import {
  ltLogger,
  getQueryParams,
  container as ltPhxAppContainer,
  loadLtAnalytics,
  setFetchFunction,
  getFetchFunction,
} from './bootstrap';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initStore, getStore } from './store';
import { getStep, changeMeta, handleNextSteps } from './actions/formActions';
import * as stackactiontypes from './common/stackactiontypes';
import enviroConfig, { getMyLtRedirectPath } from './reducers/config';
// import "babel-polyfill";
import './common/polyfills';
import './tu-blackbox';
import { addAuthInitListener } from './actions/process/authInit';

require('./favicon.ico'); // Tell webpack to load favicon.ico
import './styles/global/global.scss';
import './styles/global/header.scss';
import './styles/global/layout.scss';
import './styles/global/footer.scss';
import './styles/global/slider.scss';
import './styles/global/uploader.scss';
//import './styles/global/tactile.scss';
import './styles/global/interstitials.scss';
import { getFormId as getFormIdFromPath } from './utils/pathHelper';
import { actions } from 'react-redux-form';
import { createBrowserHistory } from 'history'; // To fixed warning Warning: Please use `require("history").createBrowserHistory` instead of `require("history/createBrowserHistory")`.
import App from './App';
import * as logtypes from './common/logtypes';
import FormApi from './api/formApi';
import {
  hasAcceptableHostName,
  isAcceptableAuthHost,
  createURL,
} from './utils/url';
import DetectAdBlock from './components/common/DetectAdBlock';

let container = null;
export const getContainer = () => container;
let initialized = false;

window.ltPhoenix = {
  createURL,
  handledClicks: {},
  firstLoad: true,
  hasAcceptableHostName,
};
if (process.env.PHOENIX_ENVIRONMENT) {
  window.ltPhoenix.env = process.env.PHOENIX_ENVIRONMENT;
}
window.formActions = actions;
window.formAdvance = handleNextSteps;
window.changeMeta = changeMeta;

const getFormId = () => {
  if (!window.ltPhoenix.formId) {
    window.ltPhoenix.formId =
      container &&
      container.hasAttribute &&
      container.hasAttribute('data-form-id')
        ? container.getAttribute('data-form-id')
        : getFormIdFromPath();
  }

  return window.ltPhoenix.formId;
};

const isThemeLT5 = () => {
  return getFetchFunction()(
    `${enviroConfig.apiConfig.rootApiServerPath}/api/getThemeInformation`,
    { credentials: 'include', referrerPolicy: 'no-referrer-when-downgrade' }
  )
    .then((response) => {
      if (response.status == 200) {
        return response.json().then((phxThemeInfoApi) => {
          const allCSS = [...document.styleSheets];
          let found =
            allCSS.length > 0 &&
            allCSS.some((sheet) => {
              let cssname =
                sheet &&
                sheet.href &&
                sheet.href.substring(sheet.href.lastIndexOf('/') + 1);
              if (
                phxThemeInfoApi.LT5ThemeCss.find((entry) => entry === cssname)
              ) {
                return true;
              }
            });
          return found || false;
        });
      } else {
        return true;
      }
    })
    .catch((error) => {
      ltLogger(logtypes.ERROR, undefined, {}, undefined, {
        error: error,
      });
      return true;
    });
};

const getFormInformation = () => {
  if (!window.ltPhoenix.formId) {
    window.ltPhoenix.formId = getFormId();
  }
  return getFetchFunction()(
    `${enviroConfig.apiConfig.rootApiServerPath}/api/getFormInformation/${window.ltPhoenix.formId}`,
    { credentials: 'include', referrerPolicy: 'no-referrer-when-downgrade' }
  ).then((response) => {
    if (response.status == 200) {
      return response.json().then((formInformation) => {
        return formInformation;
      });
    } else {
      throw `Could not read Form metadata of ${window.ltPhoenix.formId} `;
    }
  });
};

const init = ({
  container: customContainer,
  formId,
  formData,
  config = {},
  callbacks = {},
  marketingKeys = {},
  fetch: xFetch,
  matchDirective,
} = {}) => {
  if (callbacks && Object.getOwnPropertyNames(callbacks).length > 0) {
    window.ltPhoenix['callbacks'] = callbacks;
  }

  if (!window.ltPhoenix.formId && formId) {
    window.ltPhoenix.formId = formId;
  }

  if (initialized === true) return; // to restrict multiple inits
  container = customContainer || ltPhxAppContainer;
  if (container === null) return;

  // Always ensure that this class is present
  container.classList.add('ltFormAppContainer');

  window.ltPhoenixDisableInternalAnalytics =
    'disableInternalAnalytics' in config
      ? config.disableInternalAnalytics === true
      : container.getAttribute('data-disableInternalAnalytics')
      ? container.getAttribute('data-disableInternalAnalytics') === 'true'
      : window.ltPhoenixDisableInternalAnalytics || false;

  if (!window.ltPhoenixDisableInternalAnalytics) {
    loadLtAnalytics();
  }

  window.disableAutoScroll =
    'disableAutoScroll' in config
      ? config.disableAutoScroll === true
      : container.hasAttribute('data-disable-auto-scroll');

  window.preventRedirect =
    'preventRedirect' in config
      ? config.preventRedirect === true
      : container.hasAttribute('data-prevent-redirect');

  if (matchDirective) {
    window.ltPhoenix['matchDirective'] = matchDirective;
  }

  try {
    if (!location.search) {
      if (location.href.search('%3F') > -1) {
        if (location.hostname.search('lendingtree.com') > -1) {
          location.href = location.href.replace(/%3F/, '?');
        }
      }
    }

    if (!window.ltPhoenixConfig) {
      window.ltPhoenixConfig = {};
    }
    if (!window.ltPhoenixConfig.marketingOverrides) {
      window.ltPhoenixConfig.marketingOverrides = {};
    }
    window.ltPhoenixConfig.marketingOverrides = {
      ...window.ltPhoenixConfig.marketingOverrides,
      ...marketingKeys,
    };
    const mtaid =
      'mtaid' in config
        ? config.mtaid
        : marketingKeys.mtaid
        ? marketingKeys.mtaid
        : container.getAttribute('data-mtaid');
    if (mtaid) {
      window.ltPhoenixConfig.marketingOverrides.mtaid = mtaid;
    }
    const partnerTracking =
      'partnerTracking' in marketingKeys
        ? marketingKeys.partnerTracking
        : container.getAttribute('data-partnerTracking');
    if (partnerTracking) {
      window.ltPhoenixConfig.marketingOverrides.partnerTracking =
        partnerTracking;
    }

    // Zach James :: PHX-2721: 'ifr' stands for 'InsideFlowRedirect'
    // If ifr is 0, we prevent redirects to inside flow
    // If ifr is anything else or not present, we check whether we need to redirect to inside flow
    window.ltDisableAuthenticationRedirect =
      'disableAuthenticationRedirect' in config
        ? config.disableAuthenticationRedirect === true
        : getQueryParams('ifr') === '0' ||
          container.getAttribute('data-disable-authentication-redirect') ===
            'true';
    window.ltDisableIdleEvent =
      'disableIdleEvent' in config
        ? config.disableIdleEvent === true
        : container.getAttribute('data-disable-idle-event') === 'true';

    /**
     * PHX-5556: specify whether to disable redirection of an unauthenticated
     * user trying to access an inside flow form from a non-MyLT domain.
     */
    window.ltDisableUnauthMyLtRedirect =
      'disableUnauthMyLtRedirect' in config
        ? config.disableUnauthMyLtRedirect === true
        : getQueryParams('disableUnauthMyLtRedirect') === 'true' ||
          container.getAttribute(
            'data-disable-unauthenticated-mylt-redirect'
          ) === 'true';

    if (!formId) {
      formId = getFormId();

      if (formId === undefined) {
        throw new Error(`FormId was not found in Url: ${location.href}`);
      }
    }
  } catch (error) {
    ltLogger(logtypes.ERROR, undefined, {}, undefined, {
      error: error,
    });
  }

  const { store, persistor } = initStore(formId);

  const history = createBrowserHistory();

  window.onpageshow = function (evt) {
    // If persisted then it is in the page cache, force a reload of the page. ios backforward cache fix
    if (evt && evt.persisted) {
      document.body.style.display = 'none';
      location.reload();
    }
  };

  history.listen((location, action) => {
    if (location.state) {
      //if action is available use its value to move backwards or forwards
      let stackAction = action
        ? action == 'POP'
          ? stackactiontypes.POP
          : stackactiontypes.PUSH
        : store.getState().formMeta.stepIndex >= location.state.viewIndex
        ? stackactiontypes.POP
        : stackactiontypes.PUSH;

      let { stepIndex, ...cleanMeta } = Object.assign(
        {},
        store.getState().formMeta
      );
      delete cleanMeta.stepName;
      if (stepIndex === 1 && stackAction === stackactiontypes.PUSH) {
        return;
      }
      cleanMeta.stepSet =
        stackAction === stackactiontypes.PUSH
          ? store.getState().formMeta.stepSet
          : location.state.stepId;
      if (stackAction === stackactiontypes.PUSH) {
        if (document.getElementById('phoenixform'))
          document.getElementById('phoenixform').submit();
      } else
        store.dispatch(
          getStep(
            formId,
            {
              formMeta: { ...cleanMeta },
              formData:
                stackAction === stackactiontypes.PUSH
                  ? store.getState().formData
                  : {},
            },
            stackAction,
            false
          )
        );
    }
  });

  function renderApp(data = { autoSubmit: false, treeAuthId: '' }) {
    const variation = `${window.ltPageName || 'none'} | ${
      window.ltPageTheme || 'none'
    } | ${window.ltFormTheme || 'none'}`;

    if (window.LT5theme === undefined) {
      isThemeLT5()
        .then((result) => {
          window.LT5theme = result;
        })
        .catch(() => (window.LT5theme = true));
    }
    if (window.ltExplainTextPosition === undefined) {
      window.ltExplainTextPosition = 'topRight';
    }
    if (formId !== undefined) {
      const getStepData = {
        formMeta: {
          vid: variation,
          referrer: document.referrer,
          // PHX-6274 :: INFO
          // Setting product from ProductReceived if exist else check with templateProduct value
          product: window.ltPhoenix.ProductReceived || window.templateProduct || 'NOPRODUCT',
          clientTimeStamp: Date.now().toString(),
          autoSubmit: data.autoSubmit,
          autoSubmitStatus: data.autoSubmit,
          treeAuthId: data.treeAuthId,
          insideFlow: data.insideflowform ? true : false,
        },
      };

      const getStepParams = [formId, getStepData];

      if (formData != null) {
        // Cast init formData to strings
        let formDatakeys = Object.keys(formData);
        let stringifiedFormData = {};

        for (let i = 0; i < formDatakeys.length; i++) {
          stringifiedFormData[formDatakeys[i]] = String(
            formData[formDatakeys[i]]
          );
        }

        getStepData.initialFormData = stringifiedFormData;

        getStepParams.push(stackactiontypes.PUSH, false);
      }

      store.dispatch(getStep(...getStepParams));
    }

    render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DetectAdBlock pathname={window.location.pathname} />
          <App />
        </PersistGate>
      </Provider>,
      container
    );
    document.getElementsByTagName('body')[0].classList.remove('bodyReset');
  }

  xFetch && setFetchFunction(xFetch);

  addAuthInitListener();

  getFormInformation()
    .then((formInformation) => {
      const disclosureEventOptions = {};
      if (formInformation.psuedoVertical) {
        disclosureEventOptions.product = formInformation.psuedoVertical;
      } else {
        disclosureEventOptions.product = formInformation.product;
      }
      const disclosureEvent = new CustomEvent('ltPhxIntendedProduct', {
        detail: {
          ...disclosureEventOptions,
        },
      });
      window.dispatchEvent(disclosureEvent);
      window.ltPhoenix.Vertical = formInformation?.product || 'Unknown';

      // PHX-6274 :: INFO
      // Setting the product value from formInformation into a new window variable
      window.ltPhoenix.ProductReceived = formInformation?.product;

      if (
        hasAcceptableHostName(window.location.href) &&
        isAcceptableAuthHost()
      ) {
        getFetchFunction()(enviroConfig.apiConfig.authenticationPath, {
          credentials: 'include',
          referrerPolicy: 'no-referrer-when-downgrade',
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json().then(function (data) {
                let treeAuthUid =
                  data && data.treeAuthUid ? data.treeAuthUid : '';
                let redirectUrl = undefined;
                /**
                 * PHX-5556: MyLT inside flow redirect for unauthenticated users.
                 * If a user is trying to access an inside flow form from a non-MyLT domain,
                 * check config to see if they should be redirected to MyLT to authenticate.
                 * If so, they will be sent to a MyLT splitter URL for the specified product.
                 */
                if (
                  formInformation.insideflowform &&
                  !treeAuthUid &&
                  !window.ltDisableUnauthMyLtRedirect
                ) {
                  redirectUrl = getMyLtRedirectPath(formInformation.product);
                } else if (
                  !formInformation.insideflowform &&
                  treeAuthUid &&
                  !window.ltDisableAuthenticationRedirect
                ) {
                  redirectUrl = getMyLtRedirectPath(formInformation.product);
                }
                if (redirectUrl) {
                  redirectUrl = redirectUrl.replace(/"/g, '');
                  let redirectUrlObj = new URL(redirectUrl);
                  let redirectParams = new URLSearchParams(
                    redirectUrlObj.search.slice(1)
                  );
                  let currentParams = new URLSearchParams(
                    window.location.search.slice(1)
                  );
                  for (const param of currentParams) {
                    if (!redirectParams.has(param[0])) {
                      redirectParams.append(param[0], param[1]);
                    }
                  }
                  redirectUrlObj.search = redirectParams.toString();
                  redirectUrl = redirectUrlObj.href;
                  try {
                    window.location.replace(redirectUrl);
                  } catch (e) {
                    window.location = redirectUrl;
                  }
                } else {
                  renderApp({
                    ...formInformation,
                    treeAuthId: treeAuthUid,
                  });
                }
              });
            } else {
              renderApp();
            }
          })
          .catch((error) => {
            let { formMeta, formDefinition } = store.getState();
            let localerr;
            if (error.stack && /[\n\r]/g.test(error.stack)) {
              localerr = error;
              localerr.message = `Check For Authenticated User: ${
                localerr && localerr.message
                  ? localerr.message
                  : 'No error provided'
              }`;
            } else {
              localerr = new Error(
                `Check For Authenticated User: ${
                  error && error.message ? error.message : 'No error provided'
                }`
              );
            }

            ltLogger(logtypes.ERROR, undefined, {}, undefined, {
              error: localerr,
              metaData: Object.assign({}, formMeta, {
                displayedSteps: JSON.parse(
                  JSON.stringify(formDefinition ? formDefinition.stepSet : [])
                ),
              }),
            });
            renderApp();
          });
      } else {
        if (formInformation) {
          renderApp({ ...formInformation });
        } else {
          renderApp();
        }
      }
    })
    .catch(() => renderApp());
  initialized = true;
};

window.ltPhoenix.init = init;
window.ltPhoenix.deInit = () => {
  //We can call other functions or add logic to cleanup other phoenix objects here
  stopActiveProspect('PhoenixDeinitializedEvent');
  delete window.LT5theme;
  unmountComponentAtNode(container);
};

if (
  ltPhxAppContainer !== null &&
  ltPhxAppContainer.getAttribute('data-disable-auto-init') !== 'true'
) {
  window.ltPhoenix.init();
}

const stopActiveProspect = (event) => {
  if (window.trustedFormStopRecording) {
    window.trustedFormStopRecording();
    let scriptList = document.getElementsByTagName('script');
    let convertedNodeList = Array.from(scriptList);
    let apScript = convertedNodeList.find(
      (script) => script.src && script.src.includes('cdn.trustedform.com')
    );
    if (
      apScript &&
      apScript.parentNode &&
      typeof apScript.parentNode.removeChild === 'function'
    ) {
      apScript.parentNode.removeChild(apScript);
    }
    delete window.trustedForm;
    delete window.trustedFormCertIdCallback;
    delete window.trustedFormStartRecording;
    delete window.trustedFormStopRecording;
    getContainer().dispatchEvent(
      new CustomEvent('ltAPRecordingStopped', {
        bubbles: true,
        detail: 'AP recording stopped ' + event,
      })
    );
  }
};
window.ltPhoenixActions = {
  captureAbandonedEmail: (email) => {
    const store = getStore();

    let { formMeta, formDefinition } = store.getState();
    // we don't need to save it to ReduxStore, since we are saving the email id to server side session but better to keep
    //both server and client state in sync
    store.dispatch(
      changeMeta({ formMeta: { ...formMeta, abandonedEmailCollected: true } })
    );
    store.dispatch(actions.change('formData.EmailAddress', email));
    FormApi.updateAbandonedEmail({ formid: formDefinition.formId, email });
  },
  flagInitSession: () => {
    const store = getStore();
    const { formMeta } = store.getState();

    store.dispatch(
      changeMeta({ formMeta: { ...formMeta, initSessionHasBeenCalled: true } })
    );
  },
  stopAPRecording: stopActiveProspect,
};
