import { ltLogger, getFetchFunction, getParamsFromUrl } from '../bootstrap';
import { buildCorsHeaders, createURL } from './url';
import * as logtypes from '../common/logtypes';

export const sendEventToKafka = (eventName, eventProperties, options) => {
  const headers = {
    'content-type': 'application/json; charset=utf-8',
    Accept: 'application/json',
    'phoenix-package': `${phoenixname};${phoenixversion};${
      process.env.BUILD_NUMBER
        ? process.env.BUILD_NUMBER.substring(0, 8)
        : 'none'
    };`,
  };
  const url = createURL('/api/track/KafkaEvent');
  const body = JSON.stringify({
    name: eventName,
    properties: eventProperties,
    options: { ...options, params: getParamsFromUrl(window.location.href) },
  });
  getFetchFunction()(url, {
    method: 'POST',
    body,
    headers: { ...headers, ...buildCorsHeaders(url) },
  })
    .then((response) => {
      if (response.statusCode >= 200 || response.statusCode < 300) {
        ltLogger(logtypes.SUCCESS, eventName, {}, undefined, {});
      }
    })
    .catch((err) => {
      ltLogger(logtypes.ERROR, undefined, {}, undefined, {
        error: err,
      });
    });
};
