import React from 'react';
import { connect } from 'react-redux';
import { Errors, Control, actions } from 'react-redux-form';
import Subtext from '../subText';
import ValidationIndicator from '../validationIndicator';
import WithAddOns from '../inputAddOns';
import * as formatTypes from '../../../utils/formatTypes';
import { getType } from '../../../utils/stepFunctions';
import {
  runActionsAfterStep,
  setFormWarning,
} from '../../../actions/formActions';
import {
  getValidations,
  getValidationMessages,
  getAsyncValidations,
  getMaxLength,
} from '../../../validators/validators';
import ErrorWrapper from '../../common/errorWrapper';
import MaskedTextControl from './maskedTextControl';
import PropTypes from 'prop-types';
import { isNumberKey } from '../../../utils/characterCheck';
import { titleCase } from '../../../utils/titleCase';
import { reportFieldEngagement } from '../../../bootstrap';

const errorComponentClassName = 'ltErrorText';
// Move to helper function, import into whatever controls we want to avoid real time validation
const controlHasError = ({
  valid,
  retouched,
  focus,
  touched,
  pristine,
  submitFailed,
  submitted,
}) => {
  let propertyEdited = touched || retouched;
  let propertySubmitted = submitFailed || submitted;
  /*
  if submitted is true and touched is false, it means that submitted or touched has been set explicitly by code
  and this is the step load. dont show errors in this case
  */
  let isStepLoad = !touched && submitted;
  return (
    !isStepLoad &&
    propertyEdited &&
    !pristine &&
    !valid &&
    (!focus ||
      propertySubmitted) /*show error if component is out of focus or component value has been submitted for api validation*/
  );
};

const ControlWithAddons = WithAddOns(Control);

const TextControl = ({
  step,
  formatter,
  modelParser,
  type,
  inputMask,
  autoCompleteText,
  placeholderChar,
  warnings,
  autoFocus,
  externalValidationsOnBlur,
}) => {
  const [lastReportedValue, setLastReportedValue] = React.useState('');
  let errorClassName = warnings[step.attributeid]
    ? 'ltHasWarning'
    : 'ltHasError';

  let checkDecimal = function (textBoxValue, keyPressed, step) {
    return step.isDecimalAllowed &&
      keyPressed == '.' &&
      textBoxValue.indexOf('.') == -1
      ? false
      : true;
  };

  const handleFieldEngagement = (fieldName, fieldValue) => {
    if (fieldValue) {
      let isPopulated =
        fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
      let formatterName =
        formatter && formatter.name ? formatter.name : undefined;
      if (formatterName === 'dateFormatter') {
        // PHX-5081 :: INFO / TECH-DEBT
        // This assumes forward slash is the DOB separator,
        // which might not be the case in the future
        let values = fieldValue.split('/');
        const numRegex = new RegExp('^[0-9]+$');
        for (let i = 0; i < values.length; i++) {
          if (!numRegex.test(values[i])) {
            isPopulated = false;
            break;
          }
        }
      } else if (formatterName === 'phoneFormatter') {
        isPopulated = fieldValue.length === 10;
      } else if (fieldName === 'SSN') {
        isPopulated = fieldValue.length === 9;
      } else if (fieldName === 'Last4SSN') {
        isPopulated = fieldValue.length === 4;
      }
      if (isPopulated && fieldValue !== lastReportedValue) {
        reportFieldEngagement(fieldName, fieldValue);
        setLastReportedValue(fieldValue);
      }
    }
  };

  let isShowDecimalAllowed =
    step.alwaysshowdecimal && parseInt(step.digitspastdecimal) > 0;

  const classNames = [`ltFormGroupContent${titleCase(step.nodetype, 0)}`];

  if (step.isFieldReadonly === true) {
    classNames.push('ltFieldReadOnly');
  }

  return (
    <div className={classNames.join(' ')}>
      <ControlWithAddons
        addons={step.addons}
        isPasswordViewEnabled={step.isPasswordViewEnabled}
        id={`formData.${step.attributeid}`}
        model={`formData.${step.attributeid}`}
        inputMask={inputMask}
        placeholderChar={placeholderChar}
        component={inputMask ? MaskedTextControl : ErrorWrapper}
        formatter={formatter}
        parser={modelParser}
        autoComplete={autoCompleteText}
        validators={getValidations(step.validation, step.formatter)}
        //disabled={{validating: true}}
        debounce={parseInt(step.debounce)}
        updateOnEnter={!warnings[step.attributeid]}
        updateOn={isShowDecimalAllowed ? ['blur'] : ['change']}
        readOnly={step.isFieldReadonly === true}
        onKeyPress={(e) => {
          if (step.isFieldReadonly) return;

          if (e.key === 'Enter') {
            // Zach James :: PHX-5081 :: INFO
            // We need to take into consideration formatters here
            // Not currently applicable to on blur reporting
            let fieldValue = e.target.value;
            if (formatter && formatter.name === 'currencyFormatter') {
              fieldValue = fieldValue.replace(/\$|,/g, '');
            }
            handleFieldEngagement(step.attributeid, fieldValue);
          }
          if (
            getType(step.formatter) === formatTypes.PHONE &&
            isNaN(e.key) &&
            checkDecimal(e.target.value, e.key, step) &&
            e.key !== 'Enter'
          ) {
            e.preventDefault();
          } else if (step.formatter === 'percent') {
            return isNumberKey(e);
          }
        }}
        onBlur={(event, field) => {
          if (step.isFieldReadonly) return;

          handleFieldEngagement(step.attributeid, field.value);
          externalValidationsOnBlur(event, field);
        }}
        // onChange={e => {
        //   //this only exists for old droid issue
        //   if (e.currentTarget && navigator.userAgent.match(/Android/i)) {
        //     setTimeout(() => {
        //       try {
        //         e.currentTarget.selectionStart = e.currentTarget.value.length;
        //         e.currentTarget.selectionEnd = e.currentTarget.value.length;
        //       }
        //       catch (e) {/*not needed to report*/
        //       }
        //     }, 0);
        //   }
        // }
        //}
        //updateOnEnter={false}
        changeAction={(model, value, event) => (dispatch) => {
          value = value.trim();
          if (isShowDecimalAllowed && event && !isNaN(parseFloat(value))) {
            value = parseFloat(value).toFixed(step.digitspastdecimal);
          } else if (
            !step.isDecimalAllowed &&
            step.isDecimalAllowed != undefined &&
            value.indexOf('.') >= 0 &&
            !isNaN(value)
          ) {
            value = Math.floor(value).toString();
          }

          dispatch(actions.change(model, value));

          let asyncValidations = getAsyncValidations(step.validation);
          let asyncValidationFields = [];

          if (asyncValidations) {
            // let asyncValidationFields = [];
            for (let i = 0; i < asyncValidations.length; i++) {
              if (asyncValidations[i].validationtype) {
                asyncValidationFields.push(asyncValidations[i].validationtype);
              }
            }
          }

          dispatch(actions.resetValidity(model, asyncValidationFields));
          // Added to trigger warning to clear when the field is updated.  Without this
          // if a user updates a field with a warning and clicks continue without losing
          // focus of the field or hitting enter, the form will submit without rechecking
          dispatch(setFormWarning({ [model]: false }));

          if (step.actionsafterchange.length > 0) {
            dispatch(
              runActionsAfterStep(null, step, (val) => formatter(val, true))
            );
          }
        }}
        placeholder={step.placeholder}
        type={type}
        //autoComplete={(step.mask) ? "new-password" : "off"}
        autoFocus={window.innerWidth >= 768 && autoFocus}
        onFocus={function (e) {
          let val = e.currentTarget.value;
          e.currentTarget.value = '';
          e.currentTarget.value = val;
        }}
        mapProps={{
          className: ({ fieldValue }) => {
            let classes = ['ltFormControl', step.attributeid];
            if (
              step.mask &&
              type !== formatTypes.PASSWORD &&
              fieldValue.value
            ) {
              classes.push('mask-input');
            }
            if (controlHasError(fieldValue)) {
              classes.push(errorClassName);
            }
            return classes.join(' ');
          },
          value: (props) => (props.viewValue ? props.viewValue : ''),
          hasError: ({ fieldValue }) => controlHasError(fieldValue),
          step: step,
          maxLength: ({ viewValue, modelValue }) => {
            const value =
              getMaxLength(step) +
              (viewValue && modelValue
                ? `${viewValue}`.length - `${modelValue}`.length
                : 0);

            return Number.isNaN(value) ? null : value;
          },
          onPasted: () => modelParser,
          formatter: (props) => props.formatter,
        }}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <ValidationIndicator classExt={step.attributeid}>
            {props}
          </ValidationIndicator>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
      <Errors
        component={(props) => {
          return (
            <div
              className={`${errorClassName} ${errorComponentClassName} ${step.attributeid}`}
              /*eslint-disable react/no-multi-comp,react/no-danger*/
              dangerouslySetInnerHTML={{ __html: props.children }}
            />
          );
        }}
        wrapper={(props) => {
          return <div className="errors">{props.children[0]}</div>;
        }}
        show={(field) => controlHasError(field)}
        model={`formData.${step.attributeid}`}
        messages={getValidationMessages(step.validation)}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};

TextControl.propTypes = {
  steps: PropTypes.array,
  parser: PropTypes.func,
  step: PropTypes.object,
  formatter: PropTypes.func,
  modelParser: PropTypes.func,
  type: PropTypes.string,
  inputMask: PropTypes.array,
  autoCompleteText: PropTypes.string,
  placeholderChar: PropTypes.string,
  viewValue: PropTypes.string,
  fieldValue: PropTypes.object,
  children: PropTypes.array,
  warnings: PropTypes.object,
  autoFocus: PropTypes.bool,
  externalValidationsOnBlur: PropTypes.func,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    externalValidationsOnBlur: async (event, field) => {
      const warnings = ownProps.warnings;
      const { model, retouched, valid, validated } = field;
      const attributeId = ownProps.step.attributeid;
      const externalValidations = getAsyncValidations(ownProps.step.validation);
      if (
        externalValidations &&
        event.target &&
        event.target.value &&
        (!event.relatedTarget ||
          (event.relatedTarget &&
            event.relatedTarget.classList.contains('ltFormButton') === false))
      ) {
        let validationCallbackResult = [];
        dispatch(actions.setValidating(model));
        for (let validator of externalValidations) {
          // Check if there's an existing warning
          if (warnings[attributeId] && (!retouched || (valid && validated))) {
            continue;
          }

          dispatch(
            actions.resetValidity(`formData.${attributeId}`, [
              validator.validationtype,
            ])
          );

          const result = await validator.validationcallback(event.target.value);
          if (!result) {
            validationCallbackResult.push(validator.validationtype);

            if (
              validationCallbackResult.includes(validator.validationtype) &&
              validationCallbackResult.length > 0
            ) {
              continue;
            }

            let dispatchObject = {};
            dispatchObject[validator.validationtype] = false;

            if (validator.validationlevel === 'warn') {
              // Update state with new warning
              dispatch(setFormWarning({ [attributeId]: true }));
            }

            dispatch(
              actions.setValidity(`formData.${attributeId}`, dispatchObject)
            );
            dispatch(actions.setValidating(`formData.${attributeId}`, false));
          }
        }
        dispatch(actions.setValidating(model, false));
      }
    },
  };
}

export default connect(null, mapDispatchToProps)(TextControl);
