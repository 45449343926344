import React from 'react';
import PropTypes from 'prop-types';

const ContinueButton = ({ text, action, classExt, disabled }) => {
  return (
    <div className="ltFormButtonContainer" shouldforwarduser="true">
      {/*ie won't take click on visibility or display hidden. style hides it*/}
      <button
        type="submit"
        style={{
          padding: '0',
          height: '0',
          width: '0',
          float: 'left',
          border: 'none',
        }}
      />
      <a
        className={`ltFormButton ${classExt}${disabled ? ' disabled' : ''}`}
        onClick={disabled ? null : action}
        onKeyPress={(e) => {
          if (e.keyCode === 0) {
            e.preventDefault();
            action();
          }
        }}
        target="_blank"
        tabIndex="0"
      >
        <span className={`ltFormButtonLabelContainer ${classExt}`}>
          <span className={`ltFormButtonLabelBackground ${classExt}`} />
          <span className={`ltFormButtonLabelSuccess ${classExt}`} />
        </span>
        {!disabled ? <span className={`ltFormActionIcon ${classExt}`} /> : null}
        <div className={`ltFormActionText ${classExt}`}>{text}</div>
      </a>
    </div>
  );
};

ContinueButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  classExt: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ContinueButton;
